// @import 'https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=latin-ext,vietnamese'
// @import 'https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&subset=latin-ext,vietnamese'
// @import 'https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&amp;subset=latin-ext'
// @import 'https://fonts.googleapis.com/css?family=Oswald:300,400,700&subset=latin-ext'
@font-face {
	font-family: AkhandSoft;
	src: url(../fonts/AkhandSoft/AkhandSoft-Regular.otf); }
@font-face {
	font-family: AkhandSoft-Light;
	src: url(../fonts/AkhandSoft/AkhandSoft-Light.otf); }
@font-face {
	font-family: AkhandSoft-Extrabold;
	src: url(../fonts/AkhandSoft/AkhandSoft-Extrabold.otf); }
@font-face {
	font-family: AkhandSoft-Semibold;
	src: url(../fonts/AkhandSoft/AkhandSoft-Semibold.otf); }

@font-face {
	font-family: Tw-Cen-MT;
	src: url(../fonts/Tw/Tw-Cen-MT.TTF); }
@font-face {
	font-family: Tw-Cen-MT-Bold;
	src: url(../fonts/Tw/Tw-Cen-MT-Bold.TTF); }
@font-face {
	font-family: Tw-Cen-MT-Italic;
	src: url(../fonts/Tw/Tw-Cen-MT-Italic.TTF); }

$white: #fff;
$black: #000;
$lblack: #222;
$blue: #00b2e7;
$dblue: #006aa4;
$yellow: #ffd359;
$orange: #f73936;
$facebook: #3b5998;
$twitter: #46d4fe;
$rss: #ffa133;
$active: #ee3c43;
$focus : #0cc3ce;
// MATERIAL COLOR
$main: #000000;
$hmain: #c6755e;
$hmain-light: #fac5c9;
// MATERIAL VARIABLE
@mixin clearfix {
	content: '';
	display: table;
	width: 100%;
	clear: both; }
@mixin flatbutton {
	display: inline-block;
	@include fs(14rem);
	text-transform: uppercase;
	font-weight: 500;
	font-family: 'Roboto';
	padding: 0 16px;
	line-height: 36px;
	@include bor-rad(2px);
	&:hover {
		background: color-rgba(lighten($black,62%),0.2); }
	&:active {
		background: color-rgba(lighten($black,62%),0.4); }
	&:focus {
		background: color-rgba($black,0.12); } }
@mixin material-card {
	@include bor-rad(2px);
	box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12); }

@mixin fs($size) {
	font-size: ($size / 16); }
@mixin block($w,$h,$f) {
	display: block;
	width: $w;
	height: $h;
	float: $f; }
body {
	margin: 0;
	padding: 0;
	@include fs(18rem);
	font-family: 'Tw-Cen-MT';
	color: $main;
	line-height: 1.5;
	background-color: #ffefe4; }
//////Custom container
.ct-container {
	padding: 0 70px;
	width: 100%;
	/////75%
	@media(max-width:1440px) {
		padding: 0 52px; }
	/////62.5%
	@include media-breakpoint-down(lg) {
		padding: 0 44px; }
	@include media-breakpoint-down(md) {
		padding: 0; } }
.ct-row {
	margin: 0 -32px;
	/////75%
	@media(max-width:1440px) {
		margin: 0 -24px; }
	/////62.5%
	@include media-breakpoint-down(lg) {
		margin: 0 -20px; }
	@include media-breakpoint-down(md) {
		margin: 0; } }
/////////////////

.row {
	display: flex;
	flex-wrap: wrap; }
.hide {
	display: none !important; }
html {
	overflow: auto; }
.fullpage {
	@include block(100%,null,left);
	overflow: hidden; }
a {
	@include transition(all,0.3s);
	text-decoration: none; }
a, a:hover {
	&:focus, &:active, &:hover {
		text-decoration: none; } }
input,button, select, textarea {
	@include transition(all,0.5s);
	&:hover, &:active, &:focus {
		outline: none; } }
button {
	border: none;
	background: none;
	margin: 0;
	padding: 0;
	@include transition(all,0.3s); }
img, iframe {
	vertical-align: middle; }
main {
	clear: both; }
strong {
	span {
		font-weight: bold; } }

.flex, .flex-wrap {
	clear: both; }
.justify-content-xl-center {
	display: flex;
	clear: both;
	flex-wrap: wrap;
	@include media-breakpoint-up(xl) {
		justify-content: center; } }
.justify-content-lg-center {
	@include media-breakpoint-down(lg) {
		display: flex;
		clear: both;
		flex-wrap: wrap;
		justify-content: center; } }
.pc {
	@include media-breakpoint-down(md) {
		display: none !important; } }
.mobi {
	display: none !important;
	@include media-breakpoint-down(md) {
		display: block !important; } }
// ::-webkit-scrollbar
// 	width: 4px
// ::-webkit-scrollbar-track
// 	background: #f1f1f1
// ::-webkit-scrollbar-thumb
// 	background: #888
// 	&:hover
// 		background: #555
.vertical-scrolling {
	overflow: hidden; }
.fp-tableCell {
	display: block; }
.flex-section {
	.fp-tableCell {
		display: flex;
		flex-wrap: wrap;
		.flex-item {
			float: left;
			width: 100%; } } }
.flex-section-center {
	.fp-tableCell {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		.flex-item {
			float: left;
			width: 100%; } } }
#fp-nav {
	display: none; }

.section-between {
	.fp-tableCell {
		align-content: space-between; } }
