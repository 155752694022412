.footer {
    background-color: #000;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    padding: 25px 0px 20px;
    @include fs(18rem);
    color: #efc9af;
    /////1760 x 846 - 92%
    @media(max-width:1760px) {
        @include fs(16.56rem); }
    /////1600 x 769 - 83%
    @media(max-width:1600px) {
        @include fs(14.94rem); }
    /////1440 x 692 - 75%
    @media(max-width:1440px) {
        @include fs(13.5rem); }
    /////1366 x 656 - 72%
    @media(max-width:1366px) {
        @include fs(12.96rem); }
    /////1280 x 615 - 67%
    @media(max-width:1280px) {
        @include fs(12.06rem); }
    /////51%
    @include media-breakpoint-down(lg);
    /////28%
    @include media-breakpoint-down(sm);
    /////17%
    @include media-breakpoint-down(xs);
    ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /////1760 x 846 - 92%
        @media(max-width:1760px) {}
        /////1600 x 769 - 83%
        @media(max-width:1600px) {}
        /////1440 x 692 - 75%
        @media(max-width:1440px) {}
        /////1366 x 656 - 72%
        @media(max-width:1366px) {}
        /////1280 x 615 - 67%
        @media(max-width:1280px) {}
        /////51%
        @include media-breakpoint-down(lg) {
            flex-wrap: wrap;
            text-align: center; }
        /////28%
        @include media-breakpoint-down(sm);
        /////17%
        @include media-breakpoint-down(xs);
        li {
            /////1760 x 846 - 92%
            @media(max-width:1760px) {}
            /////1600 x 769 - 83%
            @media(max-width:1600px) {}
            /////1440 x 692 - 75%
            @media(max-width:1440px) {}
            /////1366 x 656 - 72%
            @media(max-width:1366px) {}
            /////1280 x 615 - 67%
            @media(max-width:1280px) {}
            /////51%
            @include media-breakpoint-down(lg) {
                width: 100%; }
            /////28%
            @include media-breakpoint-down(sm);
            /////17%
            @include media-breakpoint-down(xs); } } }
