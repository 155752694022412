@-webkit-keyframes rotation {
	10% {
		transform: rotate(90deg);
		-webkit-transform: rotate(90deg); }

	50%, 60% {
		transform: rotate(0deg);
		-webkit-transform: rotate(0deg); }

	90% {
		transform: rotate(90deg);
		-webkit-transform: rotate(90deg); }

	100% {
		transform: rotate(90deg);
		-webkit-transform: rotate(90deg); } }

@keyframes rotation {
	10% {
		transform: rotate(90deg);
		-webkit-transform: rotate(90deg); }

	50%, 60% {
		transform: rotate(0deg);
		-webkit-transform: rotate(0deg); }

	90% {
		transform: rotate(90deg);
		-webkit-transform: rotate(90deg); }

	100% {
		transform: rotate(90deg);
		-webkit-transform: rotate(90deg); } }

#orientLayer {
	display: none; }

@media screen and (max-width:1199px) and (min-aspect-ratio: 13 / 8) {
	#orientLayer {
		display: block; } }

.mod-orient-layer {
	display: none;
	position: fixed;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: #000;
	z-index: 9997; }

.mod-orient-layer__content {
	position: absolute;
	width: 100%;
	top: 45%;
	margin-top: -75px;
	text-align: center; }

.mod-orient-layer__icon-orient {
	background-image: url("../img/download.png");
	display: inline-block;
	width: 67px;
	height: 109px;
	transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	-webkit-animation: rotation infinite 1.5s ease-in-out;
	animation: rotation infinite 1.5s ease-in-out;
	-webkit-background-size: 67px;
	background-size: 67px; }

.mod-orient-layer__desc {
	text-shadow: none;
	margin-top: 20px;
	font-size: 15px;
	color: #fff; }

