.header {
    /////1760 x 846 - 92%
    @media(max-width:1760px) {}
    /////1600 x 769 - 83%
    @media(max-width:1600px) {}
    /////1440 x 692 - 75%
    @media(max-width:1440px) {}
    /////1366 x 656 - 72%
    @media(max-width:1366px) {}
    /////1280 x 615 - 67%
    @media(max-width:1280px) {}
    /////51%
    @include media-breakpoint-down(lg);
    /////40%
    @include media-breakpoint-down(md);
    /////28%
    @include media-breakpoint-down(sm);
    /////17%
    @include media-breakpoint-down(xs);
    .ct-header {
        padding-bottom: 45px; }
    .main-header {
        display: flex;
        flex-wrap: no-wrap;
        align-items: flex-end;
        padding-bottom: 25px;
        margin-bottom: 30px;
        border-bottom: 4px solid $main;
        /////1760 x 846 - 92%
        @media(max-width:1760px) {
            padding-bottom: calc(25px * 0.92);
            margin-bottom: calc(30px * 0.92);
            border-bottom: calc(4px * 0.92) solid $main; }
        /////1600 x 769 - 83%
        @media(max-width:1600px) {
            padding-bottom: calc(25px * 0.83);
            margin-bottom: calc(30px * 0.83);
            border-bottom: calc(4px * 0.83) solid $main; }
        /////1440 x 692 - 75%
        @media(max-width:1440px) {
            padding-bottom: calc(25px * 0.75);
            margin-bottom: calc(30px * 0.75);
            border-bottom: calc(4px * 0.75) solid $main; }
        /////1366 x 656 - 72%
        @media(max-width:1366px) {
            padding-bottom: calc(25px * 0.72);
            margin-bottom: calc(30px * 0.72);
            border-bottom: calc(4px * 0.72) solid $main; }
        /////1280 x 615 - 67%
        @media(max-width:1280px) {
            padding-bottom: calc(25px * 0.67);
            margin-bottom: calc(30px * 0.67);
            border-bottom: calc(4px * 0.67) solid $main; }
        /////51%
        @include media-breakpoint-down(lg) {
            padding-bottom: calc(25px * 0.51);
            margin-bottom: calc(30px * 0.51); }
        /////28%
        @include media-breakpoint-down(sm) {
            padding-bottom: calc(25px * 0.28);
            margin-bottom: calc(30px * 0.28); }
        /////17%
        @include media-breakpoint-down(xs) {
            flex-wrap: wrap;
            border-bottom: 2px solid $main; }
        .logo {
            width: 140px;
            float: left;
            padding-top: 70px;
            padding-left: 25px;
            /////1760 x 846 - 92%
            @media(max-width:1760px) {
                width: calc(140px * 0.92);
                padding-top: calc(70px * 0.92);
                padding-left: calc(25px * 0.92); }
            /////1600 x 769 - 83%
            @media(max-width:1600px) {
                width: calc(140px * 0.83);
                padding-top: calc(70px * 0.83);
                padding-left: calc(25px * 0.83); }
            /////1440 x 692 - 75%
            @media(max-width:1440px) {
                //width: calc(140px * 0.75)
                padding-top: calc(70px * 0.75);
                padding-left: calc(25px * 0.75); }
            /////1366 x 656 - 72%
            @media(max-width:1366px) {
                //width: calc(140px * 0.72)
                padding-top: calc(70px * 0.72);
                padding-left: calc(25px * 0.72); }
            /////1280 x 615 - 67%
            @media(max-width:1280px) {
                //width: calc(140px * 0.67)
                padding-top: calc(70px * 0.67);
                padding-left: calc(25px * 0.67); }
            /////51%
            @include media-breakpoint-down(lg) {
                width: calc(140px * 0.51);
                padding-top: calc(70px * 0.51);
                padding-left: calc(25px * 0.51); }
            /////28%
            @include media-breakpoint-down(sm);
            /////17%
            @include media-breakpoint-down(xs) {
                width: 100%;
                text-align: center;
                padding-top: calc(70px * 0.17);
                padding-left: calc(25px * 0.17);
                img {
                    width: 80px; } } }
        .main-menu {
            width: calc(100% - 140px);
            float: left;
            /////1760 x 846 - 92%
            @media(max-width:1760px) {
                width: calc(100% - (140px * 0.92)); }
            /////1600 x 769 - 83%
            @media(max-width:1600px) {
                width: calc(100% - (140px * 0.83)); }
            /////1440 x 692 - 75%
            @media(max-width:1440px) {
                width: calc(100% - (140px * 0.75)); }
            /////1366 x 656 - 72%
            @media(max-width:1366px) {
                width: calc(100% - (140px * 0.72)); }
            /////1280 x 615 - 67%
            @media(max-width:1280px) {
                width: calc(100% - (140px * 0.67)); }
            /////51%
            @include media-breakpoint-down(lg) {
                width: calc(100% - (140px * 0.51)); }
            /////17%
            @include media-breakpoint-down(xs) {
                width: 100%;
                padding-left: 15px;
                padding-right: 15px; }
            .menu {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                @include fs(27rem);
                line-height: 1;
                /////1760 x 846 - 92%
                @media(max-width:1760px) {
                    @include fs(24.84rem); }
                /////1600 x 769 - 83%
                @media(max-width:1600px) {
                    @include fs(22.41rem); }
                /////1440 x 692 - 75%
                @media(max-width:1440px) {
                    @include fs(20.25); }
                /////1366 x 656 - 72%
                @media(max-width:1366px) {
                    @include fs(19.44rem); }
                /////1280 x 615 - 67%
                @media(max-width:1280px) {
                    @include fs(18.09rem); }
                /////17%
                @include media-breakpoint-down(xs) {
                    justify-content: space-between;
                    @include fs(15rem); }
                li {
                    float: left;
                    padding: 10px 40px 0;
                    /////1760 x 846 - 92%
                    @media(max-width:1760px) {
                        padding-left: 36px;
                        padding-right: 36px; }
                    /////1600 x 769 - 83%
                    @media(max-width:1600px) {
                        padding-left: 33px;
                        padding-right: 33px; }
                    /////1440 x 692 - 75%
                    @media(max-width:1440px) {
                        padding-left: 30px;
                        padding-right: 30px; }
                    /////1366 x 656 - 72%
                    @media(max-width:1366px) {
                        padding-left: 28px;
                        padding-right: 28px; }
                    /////1280 x 615 - 67%
                    @media(max-width:1280px) {
                        padding-left: 26px;
                        padding-right: 26px; }
                    /////51%
                    @include media-breakpoint-down(lg) {
                        padding-left: 20px;
                        padding-right: 20px; }
                    /////28%
                    @include media-breakpoint-down(sm) {
                        padding-left: 11px;
                        padding-right: 11px;
                        padding-top: 15px;
                        &:first-child {
                            padding-left: 0; }
                        &:last-child {
                            padding-right: 0; } }
                    a {
                        color: $main;
                        cursor: pointer; } } } } } }
