@import '../../../bower_components/k-sass-core/src/sass/k-sass-core';

@import "_include/body";
@import "_include/_header/header001";
@import "_include/_footer/footer001";
@import "_include/_rotate/rotate";
@import "_include/_breadcrumb/breadcrumb";
/////////////////////////////////
.about {
	.title-group {
		font-family: Tw-Cen-MT-Bold;
		@include fs(40rem);
		line-height: 1;
		padding: 25px 0;
		text-transform: uppercase;
		/////1760 x 846 - 92%
		@media(max-width:1760px) {
			@include fs(36.8rem);
			padding: calc(30px * 0.92) 0; }
		/////1600 x 769 - 83%
		@media(max-width:1600px) {
			@include fs(33.2rem);
			padding: calc(30px * 0.83) 0; }
		/////1440 x 692 - 75%
		@media(max-width:1440px) {
			@include fs(30rem);
			padding: calc(30px * 0.75) 0; }
		/////1366 x 656 - 72%
		@media(max-width:1366px) {
			@include fs(28.8rem);
			padding: calc(30px * 0.72) 0; }
		/////1280 x 615 - 67%
		@media(max-width:1280px) {
			@include fs(26.8rem);
			padding: calc(30px * 0.67) 0; }
		/////51%
		@include media-breakpoint-down(lg);
		/////28%
		@include media-breakpoint-down(sm);
		/////17%
		@include media-breakpoint-down(xs); }
	.group-history {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		/////1760 x 846 - 92%
		@media(max-width:1760px) {}
		/////1600 x 769 - 83%
		@media(max-width:1600px) {}
		/////1440 x 692 - 75%
		@media(max-width:1440px) {}
		/////1366 x 656 - 72%
		@media(max-width:1366px) {}
		/////1280 x 615 - 67%
		@media(max-width:1280px) {}
		/////51%
		@include media-breakpoint-down(lg);
		/////40%
		@include media-breakpoint-down(md) {
			padding: 0 15px; }
		/////28%
		@include media-breakpoint-down(sm) {
			.left {
				padding-bottom: 30px; } }
		/////17%
		@include media-breakpoint-down(xs); }
	.group-csr {
		display: flex;
		align-items: center;
		/////1760 x 846 - 92%
		@media(max-width:1760px) {}
		/////1600 x 769 - 83%
		@media(max-width:1600px) {}
		/////1440 x 692 - 75%
		@media(max-width:1440px) {}
		/////1366 x 656 - 72%
		@media(max-width:1366px) {}
		/////1280 x 615 - 67%
		@media(max-width:1280px) {}
		/////51%
		@include media-breakpoint-down(lg);
		/////40%
		@include media-breakpoint-down(md) {
			align-items: flex-start; }
		/////28%
		@include media-breakpoint-down(sm);
		/////17%
		@include media-breakpoint-down(xs); }
	.ct-group {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		.left {
			float: left;
			width: calc(100% / 3 *2);
			padding-left: 128px;
			/////1760 x 846 - 92%
			@media(max-width:1760px) {
				padding-left: calc(128px * 0.92); }
			/////1600 x 769 - 83%
			@media(max-width:1600px) {
				padding-left: calc(128px * 0.83); }
			/////1440 x 692 - 75%
			@media(max-width:1440px) {
				padding-left: calc(128px * 0.75); }
			/////1366 x 656 - 72%
			@media(max-width:1366px) {
				padding-left: calc(128px * 0.72); }
			/////1280 x 615 - 67%
			@media(max-width:1280px) {
				padding-left: calc(128px * 0.67); }
			/////51%
			@include media-breakpoint-down(lg);
			/////40%
			@include media-breakpoint-down(md) {
				width: 100%;
				padding-left: 0; }
			/////28%
			@include media-breakpoint-down(sm);
			/////17%
			@include media-breakpoint-down(xs); }
		.right {
			float: left;
			width: calc(100% / 3);
			padding-left: 128px;
			padding-right: 100px;
			/////1760 x 846 - 92%
			@media(max-width:1760px) {
				padding-left: calc(128px * 0.92);
				padding-right: calc(100px * 0.92); }
			/////1600 x 769 - 83%
			@media(max-width:1600px) {
				padding-left: calc(128px * 0.83);
				padding-right: calc(100px * 0.83); }
			/////1440 x 692 - 75%
			@media(max-width:1440px) {
				padding-left: calc(128px * 0.75);
				padding-right: calc(100px * 0.75); }
			/////1366 x 656 - 72%
			@media(max-width:1366px) {
				padding-left: calc(128px * 0.72);
				padding-right: calc(100px * 0.72); }
			/////1280 x 615 - 67%
			@media(max-width:1280px) {
				padding-left: calc(128px * 0.67);
				padding-right: calc(100px * 0.67); }
			/////51%
			@include media-breakpoint-down(lg);
			/////40%
			@include media-breakpoint-down(md) {
				width: 100%;
				padding-left: 0;
				padding-right: 0; }
			/////28%
			@include media-breakpoint-down(sm);
			/////17%
			@include media-breakpoint-down(xs); }
		.sub-ct {
			@include fs(20rem);
			font-family: AkhandSoft;
			/////1760 x 846 - 92%
			@media(max-width:1760px) {
				@include fs(18.2rem); }
			/////1600 x 769 - 83%
			@media(max-width:1600px) {
				@include fs(16.6rem); }
			/////1440 x 692 - 75%
			@media(max-width:1440px) {
				@include fs(15rem); }
			/////1366 x 656 - 72%
			@media(max-width:1366px) {
				@include fs(14.4rem); }
			/////1280 x 615 - 67%
			@media(max-width:1280px) {
				@include fs(13.4rem); }
			/////51%
			@include media-breakpoint-down(lg) {
				@include fs(12rem); }
			/////40%
			@include media-breakpoint-down(md);
			/////28%
			@include media-breakpoint-down(sm);
			/////17%
			@include media-breakpoint-down(xs); }
		&.csr {
			.ct-left {
				display: flex;
				flex-wrap: wrap;
				background-color: #cd8a70;
				padding: 80px 60px;
				color: white;
				/////1760 x 846 - 92%
				@media(max-width:1760px) {}
				/////1600 x 769 - 83%
				@media(max-width:1600px) {}
				/////1440 x 692 - 75%
				@media(max-width:1440px) {}
				/////1366 x 656 - 72%
				@media(max-width:1366px) {}
				/////1280 x 615 - 67%
				@media(max-width:1280px) {}
				/////51%
				@include media-breakpoint-down(lg);
				/////40%
				@include media-breakpoint-down(md) {
					padding: 15px; }
				/////28%
				@include media-breakpoint-down(sm);
				/////17%
				@include media-breakpoint-down(xs) {
					padding: 10px 15px; }
				.title-group {
					padding: 0;
					padding-right: 40px;
					/////1760 x 846 - 92%
					@media(max-width:1760px) {}
					/////1600 x 769 - 83%
					@media(max-width:1600px) {}
					/////1440 x 692 - 75%
					@media(max-width:1440px) {}
					/////1366 x 656 - 72%
					@media(max-width:1366px) {}
					/////1280 x 615 - 67%
					@media(max-width:1280px) {
						padding-top: calc(30px * 0.67);
						padding-bottom: calc(30px * 0.67); }
					/////51%
					@include media-breakpoint-down(lg);
					/////40%
					@include media-breakpoint-down(md) {
						width: 100%; }
					/////28%
					@include media-breakpoint-down(sm);
					/////17%
					@include media-breakpoint-down(xs) {
						padding-bottom: 5px;
						padding-top: 0; } }
				.sub-ct {
					font-family: AkhandSoft-Light;
					.txt-top {
						padding-bottom: 60px;
						/////1760 x 846 - 92%
						@media(max-width:1760px) {
							padding-bottom: calc(60px * 0.92); }
						/////1600 x 769 - 83%
						@media(max-width:1600px) {
							padding-bottom: calc(60px * 0.83); }
						/////1440 x 692 - 75%
						@media(max-width:1440px) {
							padding-bottom: calc(60px * 0.75); }
						/////1366 x 656 - 72%
						@media(max-width:1366px) {
							padding-bottom: calc(60px * 0.72); }
						/////1280 x 615 - 67%
						@media(max-width:1280px) {
							padding-bottom: calc(60px * 0.67); }
						/////51%
						@include media-breakpoint-down(lg);
						/////40%
						@include media-breakpoint-down(md);
						/////28%
						@include media-breakpoint-down(sm);
						/////17%
						@include media-breakpoint-down(xs) {
							padding-bottom: 10px; } }
					.list-csr {
						display: flex;
						flex-wrap: wrap;
						li {
							float: left;
							width: calc(100% / 3);
							padding-right: 5px;
							/////1760 x 846 - 92%
							@media(max-width:1760px) {}
							/////1600 x 769 - 83%
							@media(max-width:1600px) {}
							/////1440 x 692 - 75%
							@media(max-width:1440px) {}
							/////1366 x 656 - 72%
							@media(max-width:1366px) {}
							/////1280 x 615 - 67%
							@media(max-width:1280px) {}
							/////51%
							@include media-breakpoint-down(lg);
							/////40%
							@include media-breakpoint-down(md);
							/////28%
							@include media-breakpoint-down(sm);
							/////17%
							@include media-breakpoint-down(xs);
							.sub-title {
								font-weight: bold;
								padding-bottom: 18px;
								/////17%
								@include media-breakpoint-down(xs) {
									padding-bottom: 5px; } } } } } }
			.right {
				padding-top: 80px;
				/////1760 x 846 - 92%
				@media(max-width:1760px) {}
				/////1600 x 769 - 83%
				@media(max-width:1600px) {}
				/////1440 x 692 - 75%
				@media(max-width:1440px) {}
				/////1366 x 656 - 72%
				@media(max-width:1366px) {}
				/////1280 x 615 - 67%
				@media(max-width:1280px) {}
				/////51%
				@include media-breakpoint-down(lg);
				/////40%
				@include media-breakpoint-down(md) {
					padding: 30px 15px 0; }
				/////28%
				@include media-breakpoint-down(sm) {
					padding: 20px 15px 0; }
				/////17%
				@include media-breakpoint-down(xs);
				.title-group {
					padding-top: 0;
					/////28%
					@include media-breakpoint-down(sm) {
						padding-bottom: 10px; }
					/////17%
					@include media-breakpoint-down(xs); } } } } }
