.breadcrumb {
	background-color: transparent;
	padding: 0;
	line-height: 1;
	@include fs(18rem);
	margin: 0;
	/////1760 x 846 - 92%
	@media(max-width:1760px) {
		@include fs(16.56rem); }
	/////1600 x 769 - 83%
	@media(max-width:1600px) {
		@include fs(14.94rem); }
	/////1440 x 692 - 75%
	@media(max-width:1440px) {
 }		//+fs(13.5rem)
	/////1366 x 656 - 72%
	@media(max-width:1366px) {
 }		//+fs(12.96rem)
	/////1280 x 615 - 67%
	@media(max-width:1280px) {
 }		//+fs(12.06rem)
	/////51%
	@include media-breakpoint-down(lg) {
		@include fs(12.96rem); }
	/////40%
	@include media-breakpoint-down(md) {
 }		//padding: 0 15px
	/////28%
	@include media-breakpoint-down(sm);
	/////17%
	@include media-breakpoint-down(xs);
	&:after {
		display: none; }
	ul {
		padding: 0;
		display: flex;
		flex-wrap: no-wrap;
		li {
			padding-right: 25px;
			@include pos(rel,null);
			/////1760 x 846 - 92%
			@media(max-width:1760px) {}
			/////1600 x 769 - 83%
			@media(max-width:1600px) {}
			/////1440 x 692 - 75%
			@media(max-width:1440px) {}
			/////1366 x 656 - 72%
			@media(max-width:1366px) {}
			/////1280 x 615 - 67%
			@media(max-width:1280px) {}
			/////51%
			@include media-breakpoint-down(lg);
			/////40%
			@include media-breakpoint-down(md) {
				padding-right: 10px; }
			/////28%
			@include media-breakpoint-down(sm);
			/////17%
			@include media-breakpoint-down(xs);
			&::after {
				content: "\f105";
				font-family: FontAwesome;
				padding-left: 25px;
				/////1760 x 846 - 92%
				@media(max-width:1760px) {}
				/////1600 x 769 - 83%
				@media(max-width:1600px) {}
				/////1440 x 692 - 75%
				@media(max-width:1440px) {}
				/////1366 x 656 - 72%
				@media(max-width:1366px) {}
				/////1280 x 615 - 67%
				@media(max-width:1280px) {}
				/////51%
				@include media-breakpoint-down(lg);
				/////40%
				@include media-breakpoint-down(md) {
					padding-left: 10px; }
				/////28%
				@include media-breakpoint-down(sm);
				/////17%
				@include media-breakpoint-down(xs); }
			&:last-child {
				&::after {
					content: ''; } }
			a {
				color: $main; } } } }
